import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import {useCookies} from 'react-cookie'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

import {
    AuthHelpers,
    useAuthHelper,
    useCustomerType
} from '@salesforce/commerce-sdk-react'
import LoadingSpinner from '@salesforce/retail-react-app/app/components/loading-spinner'
import {
    Badge,
    Box,
    Flex,
    Icon,
    IconButton,
    Skeleton,
    useBreakpointValue,
    useDisclosure,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useCurrentBasket} from '@salesforce/retail-react-app/app/hooks/use-current-basket'
import useNavigation from '@salesforce/retail-react-app/app/hooks/use-navigation'
import {isHydrated, noop} from '@salesforce/retail-react-app/app/utils/utils'

import {useNewsLetter} from '../../clients/contentful/hooks'
import NewsLetterModal from '../../components/newsletter-modal'
import {
    colors,
    iconNames,
    localStorageKeys,
    NEWS_LETTER_OPEN_DELAY,
    styleConfigChakraKeys
} from '../../constants'
import {LanguageSelector} from '../language-selector/LanguageSelector'
import {getCookieBotId} from '../../utils/site-utils'
import {BrandLogo} from '../icons'
import Link from '../link'
import Search from '../search'
import AccountDropdown from './partials/account-dropdown'
import BurgerAnimation from './partials/burger-animation'

const ENTER_KEY = 'Enter'
const SIGN_IN_HREF = '/login'
const onClient = typeof window !== 'undefined'

/**
 * The header is the main source for accessing
 * navigation, search, basket, and other
 * important information and actions. It persists
 * on the top of your application and will
 * respond to changes in device size.
 *
 * To customize the styles, update the themes
 * in theme/components/project/header.js
 * @param  props
 * @param   {func} props.onMenuClick click event handler for menu button
 * @param   {func} props.onLogoClick click event handler for menu button
 * @param   {object} props.searchInputRef reference of the search input
 * @param   {func} props.onMy   Click click event handler for my account button
 * @param   {func} props.onMyCartClick click event handler for my cart button
 * @return  {React.ReactElement} - Header component
 */
const Header = ({
    children,
    onMenuClick = noop,
    onMyAccountClick = noop,
    onLogoClick = noop,
    onMyCartClick = noop,
    onCloseMegaNavDrawer = noop,
    mobileNavOpen,
    enableBurgerAnimation,
    header,
    setSearchShow,
    searchShow,
    hasActiveAnnouncementBar,
    ...props
}) => {
    const intl = useIntl()
    const location = useLocation()

    const {
        derivedData: {totalItems},
        data: basket
    } = useCurrentBasket()
    const {isRegistered} = useCustomerType()
    const logout = useAuthHelper(AuthHelpers.Logout)
    const navigate = useNavigation()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const isDesktop = useBreakpointValue({
        base: false,
        xl: true
    })

    const [showLoading, setShowLoading] = useState(false)
    const [enableAnimation, setEnableAnimation] = useState(false)
    // tracking if users enter the popover Content,
    // so we can decide whether to close the menu when users leave account icons
    const hasEnterPopoverContent = useRef()

    const styles = useMultiStyleConfig(styleConfigChakraKeys.header)
    /**
     * Todo Item to improve fetching of  headerData from cms
     */
    const rightData = header?.right ?? []
    const bookServices = rightData?.filter((_item, ind) => ind === 0)?.at(-1)
    const searchIconService = rightData?.filter((_item, ind) => ind === 1)?.at(-1)
    const accountSignIn = rightData?.filter((_item, ind) => ind === 2)?.at(-1)
    const accountSignOut = rightData?.filter((_item, ind) => ind === 3)?.at(-1)
    const cartIconService = rightData?.filter((_item, ind) => ind === 4)?.at(-1)

    const [isNewsletterDialogOpen, setIsNewsletterDialogOpen] =
        React.useState(false)
    const [isCookieDialogOpen, setIsCookieDialogOpen] = useState(false)
    const [isCookieBotLoadingComplete, setIsCookieBotLoadingComplete] =
        useState(false)
    const {data: newsLetter} = useNewsLetter()

    const handleNewsletterDialogClose = () => {
        setIsNewsletterDialogOpen(false)
    }

    const [cookies, setCookie, removeCookie] = useCookies(['rennai_newsLetter'])

    const onSearchClick = () => {
        onCloseMegaNavDrawer()
        setSearchShow((prevState) => !prevState)
        setEnableAnimation(true)
    }

    const onSignoutClick = async () => {
        setShowLoading(true)
        await logout.mutateAsync()
        navigate('/login')
        setShowLoading(false)
    }

    const keyMap = {
        Escape: () => onClose(),
        Enter: () => onOpen()
    }

    const handleIconsMouseLeave = () => {
        // don't close the menu if users enter the popover content
        setTimeout(() => {
            if (!hasEnterPopoverContent.current) onClose()
        }, 100)
    }

    const onMyAccountKeyDown = (e) => {
        e.key === ENTER_KEY ? onMyAccountClick() : noop
    }

    const placeholder = intl.formatMessage({
        id: 'header.field.placeholder.search',
        defaultMessage: 'SEARCH'
    })

    React.useEffect(() => {
        if (location.pathname.includes('/newsletter')) {
            setIsNewsletterDialogOpen(false)
        } else if (isCookieBotLoadingComplete && !isCookieDialogOpen) {
            setTimeout(() => {
                const alreadySubscribed = localStorage.getItem(
                    localStorageKeys.newsLetterSubscription
                )
                if (alreadySubscribed && alreadySubscribed === 'true') {
                    return
                }

                if (cookies.rennai_newsLetter) {
                    return
                }
                const in30Days = new Date(
                    new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                )
                setIsNewsletterDialogOpen(true)
                setCookie('rennai_newsLetter', true, {expires: in30Days})
            }, [NEWS_LETTER_OPEN_DELAY])
        } else if (isCookieBotLoadingComplete && isCookieDialogOpen) {
            setTimeout(() => {
                removeCookie('rennai_newsLetter')
                setIsNewsletterDialogOpen(true)
            }, [NEWS_LETTER_OPEN_DELAY])
        }
    }, [isCookieDialogOpen, isCookieBotLoadingComplete, location])

    useEffect(() => {
        const onCookieDisplay = () => {
            setIsCookieDialogOpen(true)
        }

        const onDialogClose = () => {
            setIsCookieDialogOpen(false)
        }

        const onScriptLoad = () => {
            setIsCookieBotLoadingComplete(true)
        }
        if (onClient) {
            const culture = intl?.locale?.split('-')[0] ?? 'EN'
            const script = document.createElement('script')

            window.addEventListener('CookiebotOnDialogDisplay', onCookieDisplay)
            window.addEventListener('CookiebotOnLoad', onScriptLoad)
            window.addEventListener('CookiebotOnDecline', onDialogClose)
            window.addEventListener('CookiebotOnAccept', onDialogClose)

            // Set the script attributes
            script.src = 'https://consent.cookiebot.com/uc.js'
            script.id = 'Cookiebot'
            script.setAttribute('data-cbid', getCookieBotId()) // Ensure this is your actual Cookiebot ID
            script.setAttribute('data-culture', culture.toUpperCase())
            // script.setAttribute('data-blockingmode', 'auto')
            script.type = 'text/javascript'
            script.async = true

            // Append the script to the document body
            document.body.appendChild(script)

            return () => {
                // Clean up script and event listener
                document.body.removeChild(script)
                window.removeEventListener(
                    'CookiebotOnDialogDisplay',
                    onCookieDisplay
                )
                window.removeEventListener('CookiebotOnLoad', onScriptLoad)

                window.removeEventListener('CookiebotOnDecline', onDialogClose)
                window.removeEventListener('CookiebotOnAccept', onDialogClose)
            }
        }
    }, [])

    return (
        <Box
            {...styles.container}
            {...props}
            boxShadow={{base: searchShow ? 'unset' : 'base', xl: 'unset'}}
        >
            <Box {...styles.content}>
                {showLoading && (
                    <LoadingSpinner wrapperStyles={{height: '100vh'}} />
                )}
                <Flex wrap="wrap" alignItems="center">
                    <IconButton
                        aria-label={intl.formatMessage({
                            id: 'header.button.assistive_msg.menu',
                            defaultMessage: 'Menu'
                        })}
                        icon={
                            <BurgerAnimation
                                isActive={mobileNavOpen}
                                enableAnimation={enableBurgerAnimation}
                            />
                        }
                        variant="unstyled"
                        display={{xl: 'none'}}
                        {...styles.burger}
                        onClick={onMenuClick}
                        height="44px"
                        width="44px"
                    />
                    <IconButton
                        aria-label={intl.formatMessage({
                            id: 'header.button.assistive_msg.logo',
                            defaultMessage: 'Logo'
                        })}
                        icon={<BrandLogo {...styles.logo} />}
                        {...styles.icons}
                        marginRight={{lg: '37px'}}
                        variant="unstyled"
                        onClick={onLogoClick}
                        height="44px"
                    />
                    <Box {...styles.bodyContainer}>{children}</Box>
                    {bookServices ? (
                        <Link
                            {...bookServices}
                            onLinkClick={() => {
                                instantiateBooxi()
                            }}
                            buttonStyles={styles.bookServicesBtn}
                        />
                    ) : (
                        <Skeleton
                            display={{base: 'none', xl: 'block'}}
                            width="150px"
                            height="36px"
                        />
                    )}
                    {/* locale switcher */}
                    <Box display={{base: 'none', xl: 'block'}}>
                        <LanguageSelector />
                    </Box>

                    <Box {...styles.divider}></Box>
                    <Search
                        aria-label={placeholder}
                        placeholder={placeholder}
                        {...styles.searchBar}
                        searchOpen={setSearchShow}
                        searchShow={searchShow}
                        enableAnimation={enableAnimation}
                        hasActiveAnnouncementBar={hasActiveAnnouncementBar}
                    />
                    {searchIconService ? (
                        <Link
                            onLinkClick={onSearchClick}
                            id="search-icon-btn"
                            display={{xl: searchShow ? 'none' : 'block'}}
                            icon={searchIconService?.iconName}
                            iconStyles={styles.searchIcon}
                            buttonStyles={styles.iconBtn}
                            marginRight={{base: '10px', xl: '24px'}}
                            type={searchIconService?.type || 'tertiary'}
                            _active={{background: 'unset'}}
                        />
                    ) : (
                        <Skeleton
                            width="33px"
                            height="33px"
                            marginRight="20px"
                        />
                    )}

                    {rightData?.length > 0 ? (
                        <Link
                            buttonStyles={styles.iconBtn}
                            icon={
                                isRegistered
                                    ? accountSignOut?.iconName
                                    : accountSignIn?.iconName
                            }
                            iconStyles={styles.accountIcon}
                            url={SIGN_IN_HREF}
                            isExternal={false}
                            tabIndex={0}
                            onMouseOver={
                                isDesktop && isRegistered ? onOpen : noop
                            }
                            onKeyDown={onMyAccountKeyDown}
                            aria-label={intl.formatMessage({
                                id: 'header.button.assistive_msg.my_account',
                                defaultMessage: 'My account'
                            })}
                            marginRight="7px"
                            display={{base: 'none', xl: 'block'}}
                            type={searchIconService?.type || 'tertiary'}
                        />
                    ) : (
                        <Skeleton
                            width="33px"
                            height="33px"
                            marginRight="20px"
                            display={{base: 'none', xl: 'block'}}
                        />
                    )}

                    {isRegistered && isHydrated() && (
                        <AccountDropdown
                            isOpen={!!isOpen}
                            onClose={onClose}
                            onOpen={onOpen}
                            handleIconsMouseLeave={handleIconsMouseLeave}
                            hasEnterPopoverContent={hasEnterPopoverContent}
                            keyMap={keyMap}
                            onSignoutClick={onSignoutClick}
                            styles={styles}
                        />
                    )}

                    {cartIconService ? (
                        <Link
                            aria-label={intl.formatMessage(
                                {
                                    id: 'header.button.assistive_msg.my_cart_with_num_items',
                                    defaultMessage:
                                        'My cart, number of items: {numItems}'
                                },
                                {numItems: totalItems}
                            )}
                            type={cartIconService?.type || 'tertiary'}
                            iconComponent={
                                <>
                                    <Icon
                                        as={
                                            iconNames[cartIconService?.iconName]
                                        }
                                        {...styles.cartIcon}
                                    />
                                    {basket && totalItems > 0 && (
                                        <Badge
                                            variant="notification"
                                            sx={{
                                                bg: `${colors.blackBgColor}`,
                                                color: `${colors.white}`
                                            }}
                                        >
                                            {totalItems}
                                        </Badge>
                                    )}
                                </>
                            }
                            variant="unstyled"
                            {...styles.icons}
                            mx={{base: '14px', lg: '7px'}}
                            mr={{xl: '0'}}
                            minWidth="unset"
                            onClick={onMyCartClick}
                        />
                    ) : (
                        <Skeleton width="33px" height="33px" />
                    )}
                </Flex>
            </Box>
            {newsLetter && !isCookieDialogOpen && (
                <NewsLetterModal
                    isOpen={isNewsletterDialogOpen}
                    data={newsLetter.modalCollection.items[0]}
                    onClose={handleNewsletterDialogClose}
                />
            )}
        </Box>
    )
}

Header.propTypes = {
    children: PropTypes.node,
    onMenuClick: PropTypes.func,
    onLogoClick: PropTypes.func,
    onMyAccountClick: PropTypes.func,
    onCloseMegaNavDrawer: PropTypes.func,
    onMyCartClick: PropTypes.func,
    searchInputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.elementType})
    ]),
    mobileNavOpen: PropTypes.bool,
    enableBurgerAnimation: PropTypes.bool,
    header: PropTypes.object,
    setSearchShow: PropTypes.func,
    searchShow: PropTypes.bool,
    hasActiveAnnouncementBar: PropTypes.bool
}

export default Header
